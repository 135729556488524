
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Icon from "@/components/reusable/Icon.vue";

@Component({
  components: {
    Icon
  }
})
export default class Pagination extends Vue {
  @Prop({ default: 1 }) protected pages!: number;
  @Prop({ default: 1 }) protected current!: number;
  @Prop() protected query?: string;

  get currentPage(): number {
    return this.current;
  }

  get isLastPage() {
    return this.pages === this.currentPage;
  }

  public hidePage(n: number): boolean {
    let hide = true;
    const value = this.currentPage - n;
    if (value >= -3 && value <= 3 && n !== 1 && n !== this.pages) {
      hide = false;
    }
    return hide;
  }

  public pagination(page: number): void {
    this.$emit("paginate", page);
  }

  //assuming we are using default 10 per page
  public calculateOffset(page: number): number {
    const resultsPerPage = 10;
    return resultsPerPage * (page - 1);
  }
}
