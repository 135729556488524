
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Icon from "@/components/reusable/Icon.vue";

@Component({
  components: {
    Icon
  }
})
export default class Search extends Vue {
  @Prop() placeholder!: string;
  protected searchTerm = "";

  @Watch("searchTerm")
  protected onChange() {
    if (this.searchTerm === "") {
      this.resetData();
    }
  }

  created(): void {
    if (this.$route.query && this.$route.query.q) {
      this.searchTerm = this.$route.query.q as string;
    }
  }

  public handleSearch(): void {
    if (this.searchTerm.trim()) {
      this.$emit("returnSearchTerm", this.searchTerm.toLowerCase());
    }
  }

  protected resetData(): void {
    this.$emit("reset");
  }
}
