
import { Component, Vue } from "vue-property-decorator";
import MfrTable from "@/components/manufacturer/MfrTable.vue";
import Search from "@/components/reusable/Search.vue";
import Pagination from "@/components/reusable/table/Pagination.vue";
import Icon from "@/components/reusable/Icon.vue";
import { MfrModel, MfrRequestOptions } from "@/models/mfr";
import MfrService from "@/services/mfr_service";
import { EventBus } from "@/events/index";
import UIkit from "uikit";
import { APIResponse } from "@/models/api_res";
import { StoreModule } from "@/store/types";
import { GlobalActions, GlobalGetters } from "@/store/modules/global/types";
import { namespace } from "vuex-class";
import { AuthError } from "@/services/error_service";
import ConfirmDelete from "../reusable/modals/ConfirmDelete.vue";
@Component({
  components: {
    MfrTable,
    Search,
    Pagination,
    Icon
  }
})
export default class Mfr extends Vue {
  protected failed: string[] = [];
  protected success: string[] = [];
  protected mfrs: MfrModel[] = [];
  protected mfrService = new MfrService();
  protected deleteData: MfrModel[] = [];
  protected toast = false;
  protected messageHtml = "";
  protected disableDrag = true;
  protected pages = 1;
  protected currentPage = 1;
  protected query = "";
  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetLoading))
  isLoading!: boolean;
  @(namespace(StoreModule.Global).Action(GlobalActions.AddLoading))
  setLoading: any;

  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetBusinessUnit))
  businessUnit!: string;

  created() {
    this.setLoading(true);
    if (this.$route.query.q) {
      this.disableDrag = true;
    }
    const options: MfrRequestOptions = this.getRequestOptions();
    this.getMfrs(options);
    //  if (this.$route.query.deleted) {
    //this.showDeleteToast();
    //   }
  }

  protected async showDeleteToast(): Promise<void> {
    this.showToast(
      `Category ${decodeURIComponent(
        this.$route.query.deleted as string
      )} deleted successfully.`
    );
  }

  mounted() {
    EventBus.$on(
      "deleteConfirmed",
      (id: number, name: string, final = false) => {
        this.deleteRequest(id, name, final);
      }
    );
    /** Global event listener for data deletion. Triggers & sends array of data selected for deletion through to confirmation modal.
     * This event is called from the <Delete> component (a child in the corresponding <Menu> component [@ex: <ProductMenu>, <MfrMenu>...]) and from the base <Table> component.
     */
    EventBus.$on("deleteRow", (data: MfrModel[]) => {
      this.deleteData = data;
      this.$modal.show(
        ConfirmDelete,
        { deleteData: this.deleteData, type: "manufacturer" },
        { height: "auto", adaptive: true, classes: "overflow" }
      );
    });
  }
  beforeDestroy() {
    EventBus.$off("deleteConfirmed");
    EventBus.$off("deleteRow");
    /** UIkit modals do not leave the DOM unless explicitly destroyed. Destroying them helps with buggy functionality due to dynamic data. This method loops through all of the modal ids and remove
     * them from the DOM upon vue's beforeDestroy() lifecycle hook.
     *
     * Note that typescript does not have definitions for many UIkit methods, hence //@ts-ignore flag.
     */
    const modals = [
      "#delete-modal",
      "#move-modal",
      "#confirm-moving-modal",
      "#add-model",
      "#save-modal"
    ];
    modals.forEach(selector => {
      const component = UIkit.modal(selector);
      if (component) {
        //@ts-ignore
        component.$destroy(true);
      }
    });
  }
  protected async getMfrs(optionsObject?: {}): Promise<void> {
    try {
      const payload : MfrRequestOptions= {
        ...optionsObject,
        bu: this.businessUnit
      }
      const res: APIResponse = await this.mfrService.getMfrs(payload);
      this.mfrs = res.results;
      this.pages = res.meta.total_pages;
      this.currentPage = res.meta.page;
      this.setLoading(false);
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  /**
   * Checks URL for current query terms. This should be called on every get request unless user is requesting unfiltered data
   * The object returned varies based on the endpoint being accessed. See model for each request for more details.
   */
  protected getRequestOptions(): MfrRequestOptions {
    const optionsObject = {} as any;
    if (this.query || this.$route.query.q) {
      this.query = this.$route.query.q
        ? (this.$route.query.q as string)
        : this.query;
      optionsObject.q = this.query;
    }
    if (this.$route.query.page) {
      optionsObject.page = parseInt(this.$route.query.page as string, 10);
    }
    if (this.$route.query.empty) {
      optionsObject.empty = true;
    }

    return optionsObject;
  }

  /**
   * Paginate method triggered by child <Pagination> component.
   * First, calls this.getRequestOptions() to retain any other options (like query term)
   * NOTE: page property in returned options object must be overwritten -- this.getRequestOptions will return the current page.
   *
   * Second, send options object into API get request to get appropriate page of data.
   *
   * Finally, add page query to url if it is not already there. This ensure the correct page query is retained and will fetch correct data if user refreshes.
   */
  protected paginate(page: number) {
    const options: MfrRequestOptions = this.getRequestOptions();
    options.page = page;
    this.getMfrs(options);
    if (!this.$route.query.page || this.$route.query.page !== page.toString()) {
      this.$router.push({
        query: { ...this.$route.query, page: page.toString() }
      });
    }
  }

  public receiveSearchTerm(query: string): void {
    if (this.query !== query) {
      this.query = query;
      this.$router.push({ query: { ...this.$route.query, q: query } });
    }
  }

  /** Reset search by re-requesting unfiltered data */
  public reset(): void {
    this.query = "";
    const options = { ...this.$route.query };
    delete options.q;
    this.$router.push({ query: { ...options } });
  }

  /**
   * @param id id of item to be deleted
   * @param name name of item to be deleted, used in <Toast> confirmation
   * @param final optional, default: false, flags the final item in the request array; triggers <Toast> confirmation, refreshes data
   *
   * in the <{Path}Editor> component, @param final is not used.
   */
  protected async deleteRequest(
    id: number,
    name: string,
    final = false
  ): Promise<void> {
    this.setLoading(true);
    try {
      await this.mfrService.deleteMfr(id);
      this.success.push(name);
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        this.failed.push(name);
        // EventBus.$emit("showError", err.message);
      }
    }
    if (final) {
      const optionsObject = this.getRequestOptions();
      this.getMfrs(optionsObject);
      if (this.success.length > 0) {
        EventBus.$emit(
          "showSuccess",
          `${
            this.success.length > 1 ? "Manufacturers have" : "Manufacturer has"
          } been deleted successfully.`,
          []
        );
      }
      if (this.failed.length > 0) {
        EventBus.$emit(
          "showFail",
          `The delete request for the following ${
            this.failed.length > 1 ? "manufacturers" : "manufacturer"
          } failed. Please try again.`,
          this.failed
        );
      }
      this.success = [];
      this.failed = [];
      this.deleteData = [];
    }
  }

  protected closeToast(): void {
    this.toast = false;
    this.failed = [];
    this.success = [];
  }
  protected showToast(msg: string): void {
    this.messageHtml = msg;
    this.toast = true;
  }
}
